import React from 'react'
import Layout from '../../components/Layout'

export default () => (
  <Layout>
    <section className='section' style={{
      background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 23.39%), linear-gradient(180deg, rgba(255, 255, 255, 0.85) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(114.53deg, #DDF3EB 32.2%, rgba(111, 188, 246, 0.57) 130.13%)',
      height: '100vh',
      textAlign: 'center'
    }}>
      <div className='container'>
        <div className='content'>
          <h1 style={{ fontSize: '5rem', paddingTop: '100px' }}>Thank <span className='is-blue'>you!</span></h1>
          <p>Your message has been received. We’ll get back to you as soon as possible</p>
        </div>
      </div>
    </section>
  </Layout>
)
